import React,{useContext} from 'react';
import {MainContext} from '../../context/Context';
const Silver_Anklet = () => {
const {handleWhatsAppClick} = useContext(MainContext);
    
    const cart = [
        
        {
            image: "image/silver/anklet/21.jpg",
            name: "silver Anklet",
        },
        {
            image: "image/silver/anklet/22.jpg",
            name: "silver Anklet",
        },
        {
            image: "image/silver/anklet/23.jpg",
            name: "silver Anklet",
        },
        {
            image: "image/silver/anklet/24.jpg",
            name: "silver Anklet",
        }, {
            image: "image/silver/anklet/28.jpg",
            name: "silver Anklet",
        }, {
            image: "image/silver/anklet/26.jpg",
            name: "silver Anklet",
        }, {
            image: "image/silver/anklet/27.jpg",
            name: "men Anklet",
        }, {
            image: "image/silver/anklet/28.jpg",
            name: "silver Anklet",
        },
     
    ]
 
    return (
        <div className='my-8 mt-[100px] p-3'>
           <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border p-2  hover:shadow-lg'>
                            <div className='mx-auto transition duration-300 ease-in-out hover:scale-110 h-[140px] max-w-[220px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                            <div className='text-center'>
                                <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-gold md:px-7 px-4 md:text-[16px] inline-flex text-silver items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                  
                                Enquiry Now
                                </button>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Silver_Anklet;
