import React from 'react';

const Gallery = () => {
    return (
        <div   className='w-mymxainer mt-2  '>
            <h1 className='mx-auto text-center uppercase text-2xl '>Gallery section</h1>
            <hr className='my-4 w-[50%] mx-auto text-[red] h-4'/>
            <div className='grid grid-cols-2 md:grid-cols-3'>
     <div className=' overflow-hidden' style={{border:'8px solid #D6AD60'}} ><img className='w-[600px]  transition duration-300 ease-in-out hover:scale-110 h-[300px]' src="image/models/4.jpg" alt="" /></div>
     <div className=' overflow-hidden' style={{border:'8px solid #D6AD60'}} ><img className='w-[600px]  transition duration-300 ease-in-out hover:scale-110 h-[300px]' src="image/bestseller/2.jpg" alt="" /></div>
     <div className=' overflow-hidden' style={{border:'8px solid #D6AD60'}} ><img className='w-[600px]  transition duration-300 ease-in-out hover:scale-110 h-[300px]' src="image/bestseller/8.jpg" alt="" /></div>
     <div className=' overflow-hidden' style={{border:'8px solid #D6AD60'}} ><img className='w-[600px]  transition duration-300 ease-in-out hover:scale-110  h-[300px]' src="image/bestseller/9.jpg" alt="" /></div>
     <div className=' overflow-hidden' style={{border:'8px solid #D6AD60'}} ><img className='w-[600px]  transition duration-300 ease-in-out hover:scale-110  h-[300px]' src="image/models/3.jpg" alt="" /></div>
     <div className=' overflow-hidden' style={{border:'8px solid #D6AD60'}} ><img className='w-[600px]  transition duration-300 ease-in-out hover:scale-110  h-[300px]' src="image/bestseller/16.jpg" alt="" /></div>
     </div>
   

        </div>
    );
}
export default Gallery;
