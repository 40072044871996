import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);
    const handleMenuClick = () => {
        setIsActive(!isActive);
    };
    return (
        <div>
            <header className='text-[10px]' style={{ zIndex: '1000' }}>
                <div className="container ">
                    <nav className={isActive ? 'active' : ''}>
                        <div className="menu-icons" onClick={handleMenuClick}>
                            <i className="fas text-black text-3xl fa-bars"></i>
                            <i className="fas text-black text-3xl fa-times"></i>
                        </div>
                        <Link to='' class="logo">
                            <img src="image/models/logo.png" className="md:cursor-pointer w-20 h-20" alt="" />
                        </Link>
                        <ul class="nav-list">
                            <li className='md:hidden mt-[20px]'>
                                <Link to='' class="logo">
                                    <img src="img/assets/logo.png" className="md:cursor-pointer w-18 h-12" alt="" />
                                </Link>
                            </li>
                            <li>
                                <Link onClick={handleMenuClick} className='text-[10px]' to='/'>Home</Link>
                            </li>
                           

                            <li >
                                <Link href="#">products
                                    <i class="fas fa-caret-down"></i>
                                </Link>
                                <ul style={{ width: '20rem' }} class="sub-menu  p-2" >
                                    
                                    <li>
                                        <Link>Gold
                                            <i class="fas fa-caret-down"></i></Link>
                                        <ul style={{ width: '12rem' }} class="sub-menu p-2">
                                        <li>
                                                <Link onClick={handleMenuClick} to='/earrings '>Gold Earrings</Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/bracelets '>Gold Bracelets</Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/ring '>Gold Ring</Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/bangle '>Gold Bangle</Link>
                                            </li><li>
                                                <Link onClick={handleMenuClick} to='/anklet '> Gold Anklet</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to='/udesire'></Link>
                                    </li>
                                    <li>
                                    <Link>Silver
                                            <i class="fas fa-caret-down"></i></Link>
                                            <ul style={{ width: '12rem' }} class="sub-menu p-2">
                                        <li>
                                                <Link onClick={handleMenuClick} to='/Bhartan'>Silver utensils</Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/silver_bracelets '>Silver Bracelets</Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/silver_ring '>Silver Ring</Link>
                                            </li>
                                            <li>
                                                <Link onClick={handleMenuClick} to='/silver_bangle '>Silver Bangle</Link>
                                            </li><li>
                                                <Link onClick={handleMenuClick} to='/silver_anklet '>Silver Anklet</Link>
                                            </li>
                                        </ul>
                                    </li>
                                   
                                    
                                </ul>
                            </li>
                            <li >
                                <Link href="#">Wedding Special
                                    <i class="fas fa-caret-down"></i>
                                </Link>
                                <ul style={{ width: '20rem' }} class="sub-menu  p-2" >
                                    
                                    <li>
                                        <Link onClick={handleMenuClick} to="women_wedding_jawalary">women 's'
                                           </Link>
                                    </li>
                                    
                                    <li>
                                    <Link onClick={handleMenuClick} to="Men_js">Men's
                                           </Link>
                                            
                                    </li>
                                   
                                    
                                </ul>
                            </li>
                            <li>
                                <Link onClick={handleMenuClick} to='/about'>ABOUT US</Link>
                            </li>
                            <li>
                                <Link onClick={handleMenuClick} to='/contact'>Contact </Link>
                            </li>
                            <li class="move-right btn">
                                <Link onClick={handleMenuClick} to="/bestseller">Best Seller</Link>
                            </li>

                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    );
}
export default Navbar;










