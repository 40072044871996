import React,{useContext} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MainContext } from '../context/Context';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
    slidesToSlide: 3
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
    slidesToSlide: 2
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 3,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};
const Slider = () => {
    const cart = [
        {
            image: "image/bestseller/1.jpg",
            name: "jumka",
        },
        {
            image: "image/bestseller/2.jpg",
            name: "aad",
        },
        {
            image: "image/bestseller/3.jpg",
            name: "aad",
        },{
            image: "image/bestseller/4.jpg",
            name: "mathapatti",
        },
        {
            image: "image/bestseller/5.jpg",
            name: "choora",
        }, {
            image: "image/bestseller/6.jpg",
            name: "borla",
        }, {
            image: "image/bestseller/7.jpg",
            name: "nathani",
        }, {
            image: "image/bestseller/8.jpg",
            name: "necklace",
        }, {
            image: "image/bestseller/9.jpg",
            name: "mathapatti",
        }, {
            image: "image/bestseller/10.jpg",
            name: "ranihaar",
        },{
            image: "image/bestseller/11.jpg",
            name: "tagdi",
        },{
            image: "image/bestseller/12.jpg",
            name: "bangadi",
        },{
            image: "image/bestseller/13.jpg",
            name: "gajara",

        },{
            image: "image/bestseller/14.jpg",
            name: "pacheli",

        },{
            image: "image/bestseller/15.jpg",
            name: "bichiya",

        },{
            image: "image/bestseller/16.jpg",
            name: "payal",
        },{
            image: "image/bestseller/17.jpg",
            name: "hath phool",
        },{
            image: "image/bestseller/18.jpg",
            name: "gokharu",
        }
     
    ]
 
const {handleWhatsAppClick} = useContext(MainContext);
    return (
        <div className='w-mymxcontainer px-2  my-10'>
          <h1 className='mx-auto text-center uppercase text-gold   text-2xl '>Best Seller</h1>
          <hr  className='mb-10 w-[30%] mx-auto'/>
            <Carousel  responsive={responsive} >
            {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border gap-4 p-2  mr-2 hover:shadow-lg'>
                            <div  className='mx-auto transition duration-300 ease-in-out hover:scale-110 h-[300px] md:h-[350px] max-w-[300px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                            <div className='text-center'>
                                <h1 className='text-[black] fon text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                <button onClick={() => { handleWhatsAppClick(data) }}  className="relative text-[10px] my-3 hover:bg-gold md:px-7 px-4 md:text-[16px] inline-flex text-silver items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">Enquiry Now</button>
                            </div>
                        </div>
                        )
                    })
                }
</Carousel>
        </div>
    );
}

export default Slider;
