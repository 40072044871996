import React from 'react';
import Abouts from '../components/Abouts'

const About = () => {
    return (
       <>
       <Abouts/>
       </>
    );
}

export default About;
