import React from 'react';
import Navbar from '../components/Navbar';
import { Outlet } from 'react-router-dom';
import Footers from'../components/Footers'

const Main = () => {
    
    return (
        <>
        <Navbar/>
        <Outlet/>
        <Footers/>
        </>
    );
}

export default Main;
