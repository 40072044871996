import React from 'react';

const Service = () => {
    return (
        <div>
            hellow
        </div>
    );
}

export default Service;
