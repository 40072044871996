import React,{useContext} from 'react';
import { BsFacebook, BsWhatsapp, BsInstagram, BsYoutube, BsLinkedin } from "react-icons/bs"
import { AiOutlineArrowUp } from "react-icons/ai"

import { Link } from 'react-router-dom';
import { MainContext } from '../context/Context';


const Footers = () => {
  const {scrollToTop} = useContext(MainContext);

  const socialMediaLinks = [
    {
        name: 'Facebook',
        icon: BsFacebook,
      
    },
    {
        name: 'WhatsApp',
        icon: BsWhatsapp,
      
    },
    {
        name: 'Instagram',
        icon: BsInstagram,
      
    },
    {
        name: 'YouTube',
        icon: BsYoutube,
      
    },
];

  const MenuItems = [
    {
        name: "Home",
        link: "/"
    }
    ,
    {
        name: "About",
        link: "/about"
    },
    {
        name: "Contact",
        link: "/contact"
    }
]
 
  return (

      <div className='mt-[50px] w-mycontainer'>
        <footer className="relative bg-[#D4D4D4] pt-8 pb-6">
          <div className="container mx-auto px-4">
            <div >
              <div className='grid gap-5 md:grid-cols-3'>
                <div >
                  <h4 className="text-3xl fonat-semibold text-blueGray-700">Let's keep in touch!</h4>
                  <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                    Find us on any of these platforms, we respond 1-2 business days.
                  </h5>
                  <div className="mt-6  flex lg:mb-0 mb-6 ">
                    {socialMediaLinks.map((socialMedia, index) => (
                      <socialMedia.icon className="shadow-lg transition duration-300 ease-in-out hover:scale-110 font-normal text-[30px] items-center justify-center cursor-pointer align-center rounded-full outline-none focus:outline-none mr-2"
                        key={index}
                   
                      />
                    ))}
                  </div>
                </div>
                <div className=' md:ml-10 '>
                  <span className="block uppercase text-md text-[#000000] font-semibold mb-2">Useful Links</span>
                  <ul className="list-unstyled text-[12px]">
                    
                    {
                      MenuItems.map(
                        (menu,index)=>{
                        return  <li key={index}>
                      <Link onClick={scrollToTop}  to={menu.link} className="text-blueGray-600 hover:text-blueGray-800 block pb-2 text-sm">{menu.name}</Link>
                    </li>
                        }
                      )
                    }
                  </ul>
                </div>
                <div>
                  <span className="block uppercase text-[16px] text-[#000000] font-semibold mb-2">Shop Address</span>
                  <ul className="list-unstyled text-sm ">
                    <li> himanshu plaza wali gali high school ke samne bansur  Alwar, Rajasthan </li>
                    <li className='mt-2'>shreebabajawalerys@gmail.com</li>
                    <li className='mt-2'>+91-9828310769</li>
                    <li className='mt-2'>+91-9782290786</li>
                  </ul>
                </div>
              </div>
              <div className="container flex justify-end">
                    <button onClick={scrollToTop} className="scroll-to-top-button   inline-block ">
                        <AiOutlineArrowUp className="w-[30px]  rounded-full border-red-600 border  text-center animate-bounce h-[30px] text-[blue]" />
                    </button>
                </div>
            </div>
            <hr className="my-6 border-blueGray-300" />
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div className="w-full md:w-full px-2 mx-auto text-center">
                <div className="text-sm uppercase text-blueGray-500 font-semibold ">
                  Copyright © 2023 shree baba jewellers
                </div>
              </div>
            </div>
          </div>
        
        </footer>
      </div>
  );
}

export default Footers;
