import React, {  createContext } from 'react';

const MainContext = createContext()
const Context = (props) => {
   
    const handleWhatsAppClick = (props) => {
        const phoneNumber = '9828310769';
        const message = encodeURIComponent(`Hello  shree baba jewellers , I am interested in your product  ${props.name}  and would like to get more  information,I would also like to know how I can reach out to your team. Please provide details on how to 'contact us`);

        const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(whatsappURL, '_blank');
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds a smooth scrolling animation
        });
      }
    
    return (
        <MainContext.Provider value={{handleWhatsAppClick,scrollToTop}}>
            {props.children}
        </MainContext.Provider>
    );
}

export default Context;
export {MainContext}
