import React from 'react';
import Carousel from '../components/Carousel';
import Slider from '../components/Slider';
import GoldPic from '../components/GoldPic';

const Home = () => {
    return (
        <>
          <Carousel/>
      <GoldPic/>
        <Slider/>
        </>
      
    );
}

export default Home;
