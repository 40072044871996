import About from "./pages/About"
import Contact from "./pages/Contact"
import Home from "./pages/Home"
import Main from "./pages/Main"
import OurClints from "./pages/OurClints"
import Product from "./pages/Product"
import Service from "./pages/Service"
import Bangle from "./pages/Category/Bangle"
import Bracelets from "./pages/Category/Bracelets"
import Earrings from "./pages/Category/Earrings"
import Ring from "./pages/Category/Ring"
import Anklet from "./pages/Category/Anklet"
import Silver_Bangle from "./pages/Category/Silver_Bangle"
import Silver_Bracelets from "./pages/Category/Silver_Bracelets"
import Bhartan from "./pages/Category/Bhartan"
import Silver_Ring from "./pages/Category/Silver_Ring"
import Women_wedding_Jawalary from "./pages/Category/Women_wedding_Jawalary"
import Men_js from "./pages/Category/Men_js"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Silver_Anklet from "./pages/Category/Silver_Anklet "
import BestSeller from "./pages/Category/Best_Seller"
const routes = createBrowserRouter([
  {
    path: '/',
    element: <Main/>,
    children: [
      {
        path: "",
        element: <Home/>
      },
      {
        path: '/about',
        element: <About/>
      },
      {
        path: '/contact',
        element: <Contact/>
      },
      {
        path: '/products',
        element: <Product/>
      },
      {
        path: '/service',
        element: <Service/>
      },{
        path: '/ourclint',
        element: <OurClints/>
      },{
        path: '/bangle',
        element: <Bangle/>
      },{
        path: '/bracelets',
        element: <Bracelets/>
      },{
        path: '/earrings',
        element: <Earrings/>
      },{
        path: '/ring',
        element: <Ring/>
      },{
        path: '/anklet',
        element: <Anklet/>
      },{
        path: '/women_wedding_jawalary',
        element: <Women_wedding_Jawalary/>
      },{
        path: '/men_js',
        element: <Men_js/>
      },
      {
        path: '/silver_bangle',
        element: <Silver_Bangle/>
      },{
        path: '/silver_bracelets',
        element: <Silver_Bracelets/>
      },{
        path: '/bhartan',
        element: <Bhartan/>
      },{
        path: '/silver_ring',
        element: <Silver_Ring/>
      },{
        path: '/silver_anklet',
        element: <Silver_Anklet/>
      },{
        path: '/bestseller',
        element: <BestSeller/>
      }
    ]
  }
]);
const App = () =>{
  return (
    <RouterProvider router={routes} />
  )
}

export default App
