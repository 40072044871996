import React from 'react';
import {
  BiCurrentLocation, BiLogoWhatsapp
} from "react-icons/bi"


const Contacts = () => {
  return (
    <div className='mt-20'>
      <div className="container p-6 my-2 mx-auto md:px-6">
        <section className="mb-10">
          <div
            className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div className="flex flex-wrap items-center">
              <div className="block w-full shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
                <div className="h-[500px] w-full">
             
                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3532.891210544712!2d76.35083037546673!3d27.68975707619271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDQxJzIzLjEiTiA3NsKwMjEnMTIuMyJF!5e0!3m2!1sen!2sin!4v1697357899324!5m2!1sen!2sin" className="left-0 top-0 sm:p-4 md:p-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" frameborder="0"
                    allowfullscreen ></iframe>
                </div>
              </div>
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                <div className="flex flex-wrap px-3 pt-12 pb-12 md:pb-0 lg:pt-0">
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                            stroke="currentColor" className="h-6 w-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Contact Us
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                        ShreebabaJaw@gmail.com
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          +91 9828310769
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-12 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiCurrentLocation className='text-[25px]' />
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Address
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                        himanshu plaza wali gali high school ke samne bansur  Alwar, Rajasthan 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                    <div className="align-start flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <BiLogoWhatsapp className=' animate-ping text-[25px]' />
                        </div>
                      </div>
                      <div className="ml-6 grow">

                        <a href="https://api.whatsapp.com/send/?phone=9828310769&text=Hello%2C+I+am+interested+in your services">
                          <p className="mb-2 font-bold dark:text-white">ChatNow</p>
                          <p className="text-neutral-500 dark:text-neutral-200">
                          +919351870238
                          </p>
                        </a>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          +919829334894
                          </p>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </section>
      </div>
    </div>

  );
}

export default Contacts;
