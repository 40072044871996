import React,{useContext} from 'react';
import {MainContext} from '../../context/Context';
const BestSeller = () => {
const {handleWhatsAppClick} = useContext(MainContext);
    
    const cart = [
        {
            image: "image/products/1p.jpg",
            name: "Gold rings",
        },
        {
            image: "image/products/2p.jpg",
            name: "Gold earrings",
        },
        {
            image: "image/products/3p.jpg",
            name: "Gold earrings",
        },
        {
            image: "image/products/5.jpg",
            name: "silver utensils",
        }, {
            image: "image/products/6.jpg",
            name: "silver utensils",
        }, {
            image: "image/products/7.jpg",
            name: "gold Bangles",
        }, {
            image: "image/products/8.jpg",
            name: "wedding Jawalary",
        }, {
            image: "image/products/9.jpg",
            name: "Gold necklace",
        }, {
            image: "image/products/10.jpg",
            name: "Gold Jawalary",
        },{
            image: "image/products/11.jpg",
            name: "Gold chain",
        },{
            image: "image/products/24.jpg",
            name: "Gold necklace",
        },{
            image: "image/products/25.jpg",
            name: "gold Bangles",

        },{
            image: "image/products/26.jpg",
            name: "gold Bangles",

        },{
            image: "image/products/27.jpg",
            name: "gold anklet",

        },{
            image: "image/products/28.jpg",
            name: "Gold Chain",
        },{
            image: "image/products/29.jpg",
            name: "Gold necklace",
        },{
            image: "image/products/30.jpg",
            name: "Gold necklace",
        },{
            image: "image/products/1.jpg",
            name: "Wedding set",
        },{
            image: "image/products/2.jpg",
            name: "Wedding set",
        },
     
    ]
 
    return (
        <div className='my-8 mt-[100px] p-3'>
           <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border p-2  hover:shadow-lg'>
                            <div className='mx-auto transition duration-300 ease-in-out hover:scale-110 h-[200px] max-w-[220px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                            <div className='text-center'>
                                <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-gold md:px-7 px-4 md:text-[16px] inline-flex text-silver items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                Enquiry Now
                                </button>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default BestSeller;
