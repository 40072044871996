import React from 'react';
import Contacts from '../components/Contacts'

const Contact = () => {
    return (
        <>
        <Contacts/>
        </>
    );
}

export default Contact;
