import React from 'react';
const Abouts = () => {
    return (
        <div className='  md:px-0 mt-10'>
            <div className='grid px-10  text-justify md:grid-cols-2 mt-[120px]'>
                <div className='md:w-[400px] sm:w-[200px] md:mb-0 mb-6 flex justify-center items-center' ><img src="image/models/1.jpg" alt="" /></div>
                <div className='flex px-2 flex-col gap-3'>
                    <p className='text-lg text-[#000]'>Shree Baba Jewellers -Bansur Alwar(Raj.) </p>
                    <hr />
                    <p className='text-sm text-[#777]'>At Shree Baba Jewelry Shop, we believe that jewelry is more than just adornment; it's an expression of individuality and emotion. We take pride in curating a collection that reflects the diversity of our customers' tastes and styles. Our jewelry isn't just about metal and gemstones; it's about making memories that last a lifetime.At Shree Baba Jewelry Shop, we believe in giving back to the community that has supported us throughout the years. We actively participate in local charitable events and initiatives, aiming to make a positive impact on the lives of those around us. Our commitment to social responsibility is an integral part of our busines</p>
                    <p className='text-sm text-[]'>Pur jewelry is a testament to the dedication and skill of our master craftsmen. Each piece is carefully designed and crafted to perfection. From classic designs to modern trends, we offer a wide range of jewelry that suits every occasion.If you have any questions, special requests, or need assistance, please don't hesitate to contact our dedicated customer support team. We're here to make your jewelry shopping experience exceptional.</p>
                </div>
            </div>
            <div className='grid px-10 text-justify md:grid-cols-2 mt-10'>
                <div className='flex  md:mb-0 mb-6 px-2 flex-col gap-3'>
                    <p className='text-lg  text-[#000]'>Shree Baba Jewellers -Bansur Alwar(Raj.) </p>
                    <hr />
                    <p className='text-sm  text-[#777]'>PWe're committed to ethical sourcing and responsible practices. Our gemstones and materials are sourced with care, and we support fair trade principles. When you wear our jewelry, you can do so with a clear conscience.Thank you for choosing Shree Baba Jewelry Shop. We look forward to helping you find the perfect piece that will accompany you on your life's journey.We understand that jewelry is deeply personal, often carrying sentimental value. That's why we offer customization and personalization services to help you create one-of-a-kind pieces that reflect your unique style and story. Whether it's an engagement ring, a family heirloom, or a special gift, we're here to turn your vision into reality.</p>
                    <p className='text-sm text-[]'>We understand that buying jewelry is a significant decision. That's why we promise exceptional quality, superior customer service, and the assurance that each piece is a work of art. Your satisfaction is our top priority.We invite you to explore our online store and discover the perfect piece to add to your collection or to gift to someone special. If you're in the neighborhood, visit our physical store at [Address] to experience the beauty of our jewelry up close and receive personalized assistance from our knowledgeable staff.</p>
                </div>
                <div className='md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/models/2.jpg" alt="" /></div>
            </div>
        </div>
    );
}
export default Abouts;
