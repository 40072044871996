import React,{useContext} from 'react';
import {MainContext} from '../../context/Context';
import Gallery from '../../components/Gallery'
const Women_wedding_Jawalary = () => {
const {handleWhatsAppClick} = useContext(MainContext);
    
    const cart = [
        
        {
            image: "image/gold/women_wedding_jawalry/10.jpg",
            name:"wedding jawalary set",
        },
        {
            image: "image/gold/women_wedding_jawalry/11.jpg",
            name:"wedding jawalary set",
        },
        {
            image: "image/gold/women_wedding_jawalry/13.jpg",
            name:"wedding jawalary set",
        },
        {
            image: "image/gold/women_wedding_jawalry/14.jpg",
            name:"wedding jawalary set",
        }, {
            image: "image/gold/women_wedding_jawalry/15.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/16.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/12.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/18.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/19.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/20.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/21.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/22.jpg",
            name: "wedding jawalary Borla",
        }, {
            image: "image/gold/women_wedding_jawalry/23.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/24.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/25.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/26.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/27.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/28.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/29.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/30.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/31.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/32.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/33.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/34.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/35.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/36.jpg",
            name: "wedding jawalary",
        }, {
            image: "image/gold/women_wedding_jawalry/37.jpg",
            name: "wedding jawalary",
        }
     
    ]
 
    return (
        <>
        <Gallery/>
         <div className='my-8 mt-[100px] p-3'>
           <div className='grid grid-cols-2 md:grid-cols-4  gap-4 justify-center mb-5'>
                {
                    cart.map((data, index) => {
                        return (
                            <div key={index} className='border p-2  hover:shadow-lg'>
                            <div className='mx-auto transition duration-300 ease-in-out hover:scale-110 h-[140px] max-w-[220px]'><img className='h-[100%] w-[100%]' src={data.image} alt="" /></div>
                            <div className='text-center'>
                                <h1 className='text-[#5b5b5f] text-[10px] md:text-[17px] uppercase  font-bold mt-3'>{data.name}</h1>
                                <button onClick={() => { handleWhatsAppClick(data) }} className="relative text-[10px] my-3 hover:bg-gold md:px-7 px-4 md:text-[16px] inline-flex text-silver items-center justify-start py-2 overflow-hidden font-semibold  transition-all duration-150 ease-in-out rounded  bg-gray-800 group">
                                  
                                Enquiry Now
                                </button>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
        </>
       
    );
}

export default Women_wedding_Jawalary;
